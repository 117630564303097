import Home from "../pages/Home";
import Solve from "../pages/maths/Solve";

export const AppRoutes = [
  {
    path: "/math-solve",
    label: "Maths",
    component: Solve
  },
  {
    path: "/home",
    label: "Home",
    component: Home
  }
];

export default AppRoutes;
