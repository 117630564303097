import React from "react";
import { ProblemTypes } from "../constants/maths.constants";
import { FaMinus, FaPlus, FaXmark } from "react-icons/fa6";

interface OperatorProps {
  problemType: ProblemTypes;
}

const Operator: React.FC<OperatorProps> = ({ problemType }) => (
  <>
    {problemType === ProblemTypes.ADDITION && <FaPlus />}

    {problemType === ProblemTypes.SUBTRACTION && <FaMinus />}

    {problemType === ProblemTypes.MULTIPLICATION && <FaXmark />}
  </>
);

export default Operator;
