import React, { useMemo } from "react";
import { getRandomString } from "../../utils/random.utils";

interface SimpleSolutionListProps {
  solutionList: number[];
  onSolution: (answer: number) => void;
}

const SimpleSolutionList: React.FC<SimpleSolutionListProps> = ({
  solutionList,
  onSolution
}) => {
  const id = useMemo(() => getRandomString(), []);

  return (
    <div className="my-5 text-center">
      <p>Choose the right answer - </p>

      {solutionList.map((s) => (
        <button
          key={`solution_${id}_${s}`}
          onClick={(e) => onSolution(s)}
          className="btn btn-outline-info btn-lg mx-5 my-3"
        >
          {s}
        </button>
      ))}
    </div>
  );
};

export default SimpleSolutionList;
