import React from "react";
import { ProblemTypes } from "../../constants/maths.constants";

interface TestChoiceProps {
  onStart: (problemType: ProblemTypes) => void;
}

const TestChoice: React.FC<TestChoiceProps> = ({ onStart }) => (
  <div className="row mt-5">
    <div className="col text-center size-2">
      <div>What do you want to do today?</div>

      <div className="text-center">
        <button
          className="btn btn-primary btn-block mt-3"
          onClick={() => onStart(ProblemTypes.ADDITION)}
        >
          ADDITION
        </button>
      </div>

      <div className="text-center">
        <button
          className="btn btn-primary btn-block mt-3"
          onClick={() => onStart(ProblemTypes.SUBTRACTION)}
        >
          SUBTRACTION
        </button>
      </div>

      <div className="text-center">
        <button
          className="btn btn-primary btn-block mt-3"
          onClick={() => onStart(ProblemTypes.MULTIPLICATION)}
        >
          MULTIPLICATION
        </button>
      </div>
    </div>
  </div>
);

export default TestChoice;
