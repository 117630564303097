import React, { useCallback } from "react";
import { useStopwatch } from "react-timer-hook";
import Timer from "../../atoms/Timer";
import {
  Problem,
  ProblemResult,
  ProblemTypes
} from "../../constants/maths.constants";
import {
  createProblem,
  getFakeSolutions,
  getSolution
} from "../../utils/math.utils";
import SimpleMathProblem from "../../atoms/maths/SimpleMathProblem";
import { toast } from "react-toastify";
import ResponseMessage from "../../atoms/ResponseMessage";
import { ToastConfig } from "../../utils/toast.utils";
import "../../static/math.scss";
import ResultList from "../../atoms/maths/ResultList";
import SimpleSolutionList from "../../atoms/maths/SimpleSolutionList";
import StarRatings from "../../atoms/StarRatings";
import { useNavigate } from "react-router-dom";
import TestChoice from "../../atoms/maths/TestChoice";

const Solve: React.FC = () => {
  const limit = 10;
  const [type, setType] = React.useState<ProblemTypes | undefined>();
  const [finished, setFinished] = React.useState(false);
  const [currentProblem, setCurrentProblem] = React.useState<Problem>({
    x: 0,
    y: 0
  });
  const [problems, setProblems] = React.useState<ProblemResult[]>([]);

  const navigate = useNavigate();

  const stopWatchParams = useStopwatch();

  const onStart = (t: ProblemTypes) => {
    setType(t);
    setCurrentProblem(createProblem(t));
    stopWatchParams.start();
  };

  const onRestart = () => navigate(0);

  const solution = React.useMemo(
    () => (type ? getSolution(currentProblem, type) : 0),
    [currentProblem, type]
  );

  const solutionList = React.useMemo(
    () => getFakeSolutions(solution),
    [solution]
  );

  const onSolution = useCallback(
    (answer: number) => {
      const correct = answer === solution;
      const problemList = [...problems, { ...currentProblem, answer, correct }];

      setProblems(problemList);

      correct
        ? toast.success(
            <ResponseMessage success={correct} />,
            ToastConfig.success
          )
        : toast.error(<ResponseMessage success={correct} />, ToastConfig.fail);

      if (problemList.length === limit) {
        setFinished(true);
        stopWatchParams.pause();
      } else if (type) {
        setCurrentProblem(createProblem(type));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProblem, problems, solution, type]
  );

  return (
    <div className="math-solve">
      {type ? (
        <div className="math-container">
          {finished ? (
            <>
              <Timer {...stopWatchParams} />

              <StarRatings
                rating={Math.floor(
                  problems.filter((p) => p.correct).length / 2
                )}
              />

              <ResultList problems={problems} problemType={type} />

              <button className="btn btn-info mt-3" onClick={onRestart}>
                Restart
              </button>
            </>
          ) : (
            <>
              <div className="math-left">
                <h2 className="my-5">Solve</h2>

                <SimpleMathProblem {...currentProblem} problemType={type} />

                <SimpleSolutionList
                  solutionList={solutionList}
                  onSolution={onSolution}
                />
              </div>

              <div className="math-right">
                <Timer {...stopWatchParams} />

                <ResultList problems={problems} problemType={type} />
              </div>
            </>
          )}
        </div>
      ) : (
        <TestChoice onStart={onStart} />
      )}
    </div>
  );
};

export default Solve;
