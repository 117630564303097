import { Problem, ProblemTypes } from "../constants/maths.constants";
import { getRandomNumber, randomizeList } from "./random.utils";

export const createProblem = (problemType: ProblemTypes): Problem => {
  switch (problemType) {
    case ProblemTypes.SUBTRACTION:
      const x = getRandomNumber(4, 19);
      return {
        x,
        y: getRandomNumber(3, x)
      };

    case ProblemTypes.MULTIPLICATION:
      return {
        x: getRandomNumber(2, 9),
        y: getRandomNumber(2, 9)
      };

    default:
      return {
        x: getRandomNumber(3, 19),
        y: getRandomNumber(3, 19)
      };
  }
};

export const getSolution = (
  problem: Problem,
  problemType: ProblemTypes
): number => {
  switch (problemType) {
    case ProblemTypes.SUBTRACTION:
      return problem.x - problem.y;

    case ProblemTypes.MULTIPLICATION:
      return problem.x * problem.y;

    default:
      return problem.x + problem.y;
  }
};

export const getFakeSolutions = (solution: number) => {
  const solutions = [solution];

  while (solutions.length < 6) {
    const fakeSolution = getRandomNumber(solution - 6, solution + 6);

    if (
      fakeSolution !== solution &&
      fakeSolution > 0 &&
      !solutions.includes(fakeSolution)
    ) {
      solutions.push(fakeSolution);
    }
  }

  return randomizeList(solutions);
};
