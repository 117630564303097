import React from "react";
import { ProblemTypes } from "../../constants/maths.constants";
import Operator from "../Operator";

interface SimpleMathProblemProps {
  x: number;
  y: number;
  problemType?: ProblemTypes;
}

const SimpleMathProblem: React.FC<SimpleMathProblemProps> = ({
  x,
  y,
  problemType
}) => (
  <div className="my-5">
    <span className="h1">{x}</span>

    <span className="mx-3">&#160;</span>

    {problemType && (
      <span className="h1">
        <Operator problemType={problemType} />
      </span>
    )}

    <span className="mx-3">&#160;</span>

    <span className="h1">{y}</span>

    <span className="mx-3">&#160;</span>

    <span className="h1">=</span>

    <span className="mx-3">&#160;</span>

    <span className="h1">?</span>
  </div>
);

export default SimpleMathProblem;
