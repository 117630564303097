import React from "react";
import { StopwatchResult } from "react-timer-hook";

const Timer: React.FC<StopwatchResult> = ({ seconds, minutes, isRunning }) => {
  return (
    <div className="text-center mt-5">
      <div className="h3">{isRunning ? "Timer" : "Time taken"}</div>

      <div className="h5">
        {minutes} minutes : {seconds} seconds
      </div>
    </div>
  );
};

export default Timer;
