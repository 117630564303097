import { Bounce, ToastPosition } from "react-toastify";
import { ToastIcon } from "react-toastify/dist/types";

export const ToastConfig = {
  success: {
    icon: false as ToastIcon,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
    position: "bottom-center" as ToastPosition
  },

  fail: {
    icon: false as ToastIcon,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
    position: "bottom-center" as ToastPosition
  }
};
