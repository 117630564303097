export const getRandomItem = <T extends string | number>(list: T[]): T =>
  list[Math.floor(Math.random() * list.length)];

export const randomizeList = <T>(list: T[]): T[] => {
  const newList = [...list];
  for (let i = newList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newList[i], newList[j]] = [newList[j], newList[i]];
  }
  return newList;
};

export const getRandomNumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * generated a random string upto 13 characters
 * @param length (must be less than 13)
 * @returns
 */
export const getRandomString = (length?: number) =>
  Math.random()
    .toString(16)
    .slice(2, length ? length + 2 : 15);
