export enum ProblemTypes {
  ADDITION = "addition",
  SUBTRACTION = "subtraction",
  MULTIPLICATION = "multiplication",
  DIVISION = "division"
}

export interface Problem {
  x: number;
  y: number;
}

export interface ProblemResult extends Problem {
  answer: number;
  correct: boolean;
}
