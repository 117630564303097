import React from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./constants/routes";
// import Header from "./molecules/Header";
import "react-toastify/dist/ReactToastify.css";
import "./static/animations.css";

const Container: React.FC = () => {
  return (
    <div className="container">
      <HashRouter>
        {/* <Header /> */}

        <Routes>
          {AppRoutes.map(({ path, component }) => (
            <Route key={path} path={path} Component={component} />
          ))}
          <Route
            path="/"
            element={<Navigate replace to={AppRoutes[0].path} />}
          />
        </Routes>
      </HashRouter>

      <ToastContainer style={{ width: "33rem" }} />
    </div>
  );
};

export default Container;
