export const SuccessEmoji = [
  "😀",
  "🏆",
  "✅",
  "👏",
  "💪",
  "✔",
  "👍",
  "👌",
  "🤗",
  "🙌"
];

export const FailEmoji = [
  "😢",
  "🤦‍♀️",
  "🤦🏽‍♀️",
  "🤦🏻‍♀️",
  "🤷‍♂️",
  "🙅‍♀️",
  "🤦‍♂️",
  "🤦🏻‍♂️",
  "❌",
  "👎",
  "😕",
  "👎"
];
