export const SuccessRemarks = [
  "That's Correct !!",
  "Well done !!",
  "Great job !!",
  "Awesome !!",
  "Nice !!",
  "You’re so great !!",
  "This is great !!",
  "You’re the best !!",
  "Nice work !!",
  "Excellent job !!",
  "That's impressive work !!",
  "Outstanding work !!",
  "How did you do this so fast?",
  "Terrific job !!",
  "You're doing this right !",
  "Beautiful work !!",
  "Fantastic !!"
];

export const FailRemarks = [
  "Oops, that's not correct !!",
  "Try again !!",
  "Better luck next time !!",
  "Not quite right !!",
  "This is not the right answer !!",
  "You can do this better !!"
];
