import React from "react";

interface StarRatingsProps {
  rating: number;
  maxRating?: number;
}

const StarRatings: React.FC<StarRatingsProps> = ({ rating, maxRating = 5 }) => {
  const stars = [];

  for (let i = 1; i <= maxRating; i++) {
    stars.push(
      <span key={i} style={{ color: i <= rating ? "gold" : "gray" }}>
        ★
      </span>
    );
  }

  return <div className="star-rating">{stars}</div>;
};

export default StarRatings;
