import { FailEmoji, SuccessEmoji } from "../constants/emoji";
import { FailRemarks, SuccessRemarks } from "../constants/remarks";
import { getRandomItem } from "../utils/random.utils";

const ResponseMessage = ({ success }: { success?: boolean }) => {
  const icon = getRandomItem(success ? SuccessEmoji : FailEmoji);
  const remark = getRandomItem(success ? SuccessRemarks : FailRemarks);

  return (
    <div className="text-center">
      <div className="h1">{icon}</div>
      <div className="h3">{remark}</div>
    </div>
  );
};

export default ResponseMessage;
